import React, { Component } from 'react';
import moment from 'moment';
import ReactGA from 'react-ga';

import Timer from './Timer'
import Controls from './Controls'
import Datepicker from './Datepicker'
import HolidayModal from './HolidayModal';

import Analytics from '@aws-amplify/analytics';
import Auth from '@aws-amplify/auth';


import "typeface-poppins";

const initializeReactGA = () => {
  ReactGA.initialize('UA-139064591-1');
  ReactGA.pageview('/homepage');
}

const amplifyConfig = {
  Auth: {
    identityPoolId: 'COGNITO_IDENTITY_POOL_ID',
    region: 'ap-southeast-2'
  }
}
Auth.configure(amplifyConfig);

const analyticsConfig = {
  AWSPinpoint: {
        // Amazon Pinpoint App Client ID
        appId: 'b3f8ab55a7bf40aaac1802dcb928dd1e',
        // Amazon service region
        region: 'ap-southeast-2',
        mandatorySignIn: false,
  }
}

Analytics.configure(analyticsConfig)

//Record an event
Analytics.record('User Online');

export default class Countdown extends Component {
    state = {
        currentDate: moment(),
        nextDate: moment('7/16/2019'),
        paused: false,
        showHolidays: false
    }

    
    componentDidMount(){
      this.resume();
      initializeReactGA();
    }

    componentWillUnmount(){
      this.pause();
    }

    getRemainingTime(){
        let { currentDate, nextDate } = this.state,
        diff = nextDate.diff(currentDate)

        return moment.duration(diff)
    }

    handlePausedToggle = () => {
      this.setState(({paused}, props) => {
        paused = !paused

        if (paused){
          this.pause();
        } else{
          this.resume();
        }

        const nextState = {
          paused
        }
        !paused && (nextState.currentDate = moment())
        return nextState
      })
    }

    pause(){
      clearInterval(this.interval)
    }

    resume(){
      this.interval = setInterval(() => {
        this.setState({
            currentDate: moment()
        })
      },1000)
    }

    handleDateReset = (nextDate) => {
        this.setState({
            nextDate
        })
    }

    handleHolidayToggle = () =>{
        this.setState({
          showHolidays: !this.state.showHolidays
        })
    }
    render() {
        const { paused, nextDate, showHolidays } = this.state,
                duration = this.getRemainingTime()

        return <section className="hero is-dark is-bold is-fullheight has-text-centered">
        <div className="hero-body">
          <div className="container">
            <h1 className="title is-size-1 ">
              {/* {nextDate.calendar()} */}
               COMING SOON
              {/* <button className="button is-small is-light" style={{margin: '5px 0 0 10px'}} onClick={this.handleHolidayToggle}>
                Holiday
              </button> */}
            </h1>
            <h2 class="subtitle">👷Website is under construction👷</h2>
            {/* <section className="section">
               <Timer duration={duration}/>
            </section> */}
            {/* <Datepicker onDateReset={this.handleDateReset}/> */}
            {/* <Controls paused={paused} onPausedToggle={this.handlePausedToggle}/> */}
            {/* <HolidayModal active={showHolidays} onToggle={this.handleHolidayToggle}/> */}
            </div>
          </div>
        </section>
    }
}